<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content>
        <validator v-slot="{ handleSubmit }">
          <form novalidate @submit.prevent="handleSubmit(onSubmit)">

            <span class="md-headline">{{ title }}</span>

            <rule name="nom" rules="required" v-slot="{errors}">
              <md-field>
                <label>Nom</label>
                <md-input name="name" v-model="object.name"/>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>

            <div class="buttons-panel">
              <md-button class="md-raised md-accent" @click="close">Annuler</md-button>
              <md-button type="submit" class="md-raised md-primary">Valider</md-button>
            </div>

          </form>
        </validator>
      </md-dialog-content>
    </md-dialog>

    <div class="fab-space"/>
  </div>
</template>

<script>
import LanguageService from '@/components/language/LanguageService';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import formMixin from '@/mixins/form-mixin';

extend('required', {
  ...required,
  message: messages.required,
});
export default {
  name: 'LanguageForm',
  data: () => ({
    model: 'Langue',
    is_feminine: true,
    service: LanguageService,
  }),
  components: {
    rule: ValidationProvider,
    validator: ValidationObserver,
  },
  mixins: [formMixin],
};
</script>
