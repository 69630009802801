<template>
  <div>
    <div class="lateral-padding md-layout md-alignment-center">
      <h1 class="md-layout-item">{{ title }}</h1>
      <search-bar class="md-layout-item" @input.native="filter = $event.target.value"/>
    </div>
    <md-list-item class="toolbar md-layout md-subheading">
      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-xsmall-size-100"><span>Nom</span></div>
        <div class="md-layout-item md-layout-item md-size-10 md-small-20 md-xsmall-size-15"></div>
      </div>
    </md-list-item>
    <md-list>
      <language-item v-for="language of filteredLanguages" :key="language.id" :object="language"
                     @deleted="removeObject"/>
    </md-list>

    <language-form @submit="saveObject"/>
  </div>
</template>

<script>

import LanguageItem from '@/components/language/LanguageItem.vue';
import LanguageForm from '@/components/language/LanguageForm.vue';
import LanguageService from '@/components/language/LanguageService';
import SearchBar from '@/components/shared/search-bar/SearchBar.vue';
import pageMixin from '@/mixins/page-mixin';
import notificationMixin from '@/mixins/notification-mixin';

export default {
  name: 'LanguagesPage',
  data: () => ({
    title: 'Langues',
    filter: '',
  }),
  components: {
    SearchBar,
    LanguageItem,
    LanguageForm,
  },
  mixins: [pageMixin, notificationMixin],
  computed: {
    filteredLanguages() {
      if (this.filter) {
        const exp = new RegExp(this.filter.trim(), 'i');
        return this.objects.filter((language) => exp.test(language.name));
      }
      return this.objects;
    },
  },
  async mounted() {
    try {
      this.objects = await LanguageService.index();
    } catch (err) {
      this.handleError(err);
    }
  },
};
</script>

<style scoped>

</style>
